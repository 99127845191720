import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/motion.PNG";
import emotion from "../../Assets/Projects/plugin.PNG";
import editor from "../../Assets/Projects/librarys.PNG";
import chatify from "../../Assets/Projects/news.PNG";
import suicide from "../../Assets/Projects/billing.PNG";
import bitsOfCode from "../../Assets/Projects/apex.PNG";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="News_World"
              description="A dynamic web application built with React.js, leveraging APIs to deliver real-time news updates across various categories. Seamlessly integrating user-friendly interfaces with comprehensive news coverage, it offers an immersive experience for staying informed and engaged."
              ghLink="https://github.com/Dark9027/react/tree/70a033bb96311b97e514a496baf1c25e2099bf62/My%20Second%20React%20Project"
              // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Men's fashion Startup"
              description="Successfully launched and managed a Men's Fashion Startup website on WordPress, leveraging its customizable features to showcase products, engage customers, and drive sales in the competitive fashion market."
              ghLink="https://www.apexstyle.shop"
              // demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Library Management System"
              description="Developed a robust Library Management System utilizing PHP, streamlining cataloging, lending, and user management processes, ensuring efficient library operations and enhanced user experience."
              ghLink="https://github.com/Dark9027/php/tree/7303c14b94c45ebbabb8833c935aab508eda8c32/Library"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Motion Detector"
              description="Developed a Python-based Motion Detector project leveraging computer vision techniques and OpenCV library to detect and track movement in real-time, enhancing security and surveillance applications with customizable alerting features."
              ghLink="https://github.com/Dark9027/Motion-Detector.git"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="POS System"
              description="Implemented a robust Point of Sale (POS) System using PHP, streamlining transactions, inventory management, and reporting functionalities for seamless retail operations."
              ghLink="https://github.com/Dark9027/POS.git"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Custom Wordpress Plugin"
              description="Developed a bespoke WordPress plugin using PHP, tailored to specific client needs, enhancing website functionality and providing seamless integration with WordPress CMS."
              ghLink="https://github.com/Dark9027/Wordpress-custom-plugin.git"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
